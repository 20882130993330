import {Injectable} from '@angular/core';
// import {AuthHttp} from 'angular2-jwt';
import {HttpClient, HttpHeaders,} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable(/* {
  providedIn: 'root'
} */)
export class ApiService {

  constructor(public http: HttpClient) {


  }

  /**
   * Query API by manager and action
   *
   * @param manager
   * @param action
   * @param params
   */

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  query(manager: string, action: string, params: any) {
    return this.http.post<any>(`${environment.apiUrl}?ctrl=${manager}&act=${action}`, JSON.stringify(params), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  /**
   * Handling errors.
   *
   * @param error
   * @returns {ErrorObservable}
   */
  //eslint-disable-next-line
  private handleError(error: any) {
    //eslint-disable-next-line
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(errMsg); // log to console instead
    return throwError(errMsg);
  }
}

