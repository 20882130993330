/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Dashboard',
        type :  'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '/example',
        children: [

            {
                id        : 'example',
                title     : 'Brutto',
                type      : 'basic',
                link      : '/arsresumen',
                icon : 'heroicons_outline:lightning-bolt',
                classes: {
                    wrapper: 'wrapStyle'
                },
                exactMatch: true
            },
            {
                id        : 'example',
                title     : 'Count',
                type      : 'basic',
                link      : '/arsresumen',
                icon : 'heroicons_outline:lightning-bolt',
                classes: {
                    wrapper: 'wrapStyle'
                },
                exactMatch: true
            },
        ]

    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type :   'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '/example',
        children: [

            {
                id        : 'example',
                title     : 'Resumen',
                type      : 'basic',
                link      : '/arsresumen',
                /*                 hidden: () => {
                                    if(localStorage.getItem('user_level')=="1" || localStorage.getItem('user_level')=="3"){
                                   return true;
                                    }
                                }, */
                icon : 'heroicons_outline:lightning-bolt',
                classes: {
                    wrapper: 'wrapStyle'
                },
                exactMatch: true
            },
        ]
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
